import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LoadState from "./loading";
import LoadingIndicator from "./loading-indicator";
import { Alert } from "@mui/material";
import csrf from "requests/csrf";
import { Helmet } from "react-helmet";
import SessionContext from "contexts/session";

enum SubmitState {
  Ready,
  Validate,
  Error,
  Success,
}

const categories = [
  "Charge Matrix",
  "Learning Materials",
  "Localization",
  "Key Performance Indicators",
  "Workflows",
  "Reference Build",
  "Related Content",
  "Other",
];

const ContactForm = (): JSX.Element => {
  const session = useContext(SessionContext);
  const [submitStatus, setSubmitStatus] = useState(SubmitState.Ready);
  const [loading, setLoading] = useState(LoadState.Done);
  const [first, setFirst] = useState(session.user.first_name || "");
  const [last, setLast] = useState(session.user.last_name || "");
  const [email, setEmail] = useState(session.user.email || "");
  const [internalInd, setInternalInd] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [otherText, setOtherText] = useState("");
  const [body, setBody] = useState("");
  const i18n = useIntl();

  const handleAlertClose = useCallback(() => {
    setSubmitStatus(SubmitState.Ready);
  }, []);

  const clearFields = () => {
    setFirst("");
    setLast("");
    setEmail("");
    setInternalInd(false);
    setSelectedCategories([]);
    setOtherText("");
    setBody("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(LoadState.Loading);
    setSubmitStatus(SubmitState.Validate);

    if (
      !first ||
      !last ||
      !email ||
      !body ||
      selectedCategories.length == 0 ||
      (!otherText && selectedCategories.includes("Other"))
    ) {
      return setLoading(LoadState.Error);
    }

    const staticFields = {
      first,
      last,
      email,
      internalInd,
      categories: selectedCategories.toString().replace(/,/g, ", "),
      body,
    };

    const apiBody = selectedCategories.includes("Other")
      ? {
          ...staticFields,
          categories: `${selectedCategories
            .filter((c) => c !== "Other")
            .toString()
            .replace(/,/g, ", ")}, Other: ${otherText}`,
        }
      : staticFields;

    const resp = await fetch(`/api/contact`, {
      method: "POST",
      headers: { "content-type": "application/json", "X-CSRF-TOKEN": csrf() },
      body: JSON.stringify(apiBody),
    });

    if (!resp.ok) {
      setLoading(LoadState.Error);
      setSubmitStatus(SubmitState.Error);
      clearFields();
      return;
    }

    setLoading(LoadState.Done);
    setSubmitStatus(SubmitState.Success);
    clearFields();
  };

  const handleChangeCategories = (e: ChangeEvent<HTMLInputElement>) => {
    const category = e.target.name;
    if (selectedCategories.filter((c) => c === category).length === 0) {
      return setSelectedCategories([...selectedCategories, category]);
    }

    setSelectedCategories(selectedCategories.filter((c) => c !== category));
  };

  const camelCase = (string: string) => {
    const lowerCase = string.toLowerCase();
    const words = lowerCase.split(" ");
    let camelCase = words[0];
    for (let w = 1; w < words.length; w++) {
      camelCase += words[w].charAt(0).toUpperCase() + words[w].slice(1);
    }
    return camelCase;
  };

  return (
    <Box m={5}>
      <form onSubmit={handleSubmit}>
        {/* @ts-ignore */}
        <Helmet>
          <title>
            {i18n.formatMessage({
              id: "ContactForm.contactUs",
              defaultMessage: "Contact Us",
            })}
          </title>
        </Helmet>
        <Grid container spacing={2} flexDirection="column">
          <Grid container spacing={2}>
            <Typography variant="h1">
              <FormattedMessage
                id="ContactForm.contactUs"
                defaultMessage="Contact Us"
              />
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid>
              <TextField
                required
                error={submitStatus === SubmitState.Validate && !first}
                helperText={
                  submitStatus === SubmitState.Validate &&
                  !first && (
                    <FormattedMessage
                      id="ContactForm.thisFieldIsRequired"
                      defaultMessage="This field is required."
                    />
                  )
                }
                value={first}
                label={
                  <FormattedMessage
                    id="ContactForm.firstName"
                    defaultMessage="First Name"
                  />
                }
                name="first"
                id="first"
                onChange={(e) => setFirst(e.target.value)}
                slotProps={{ htmlInput: { maxLength: 255 } }}
              />
            </Grid>
            <Grid>
              <TextField
                required
                error={submitStatus === SubmitState.Validate && !last}
                helperText={
                  submitStatus === SubmitState.Validate &&
                  !last && (
                    <FormattedMessage
                      id="ContactForm.thisFieldIsRequired"
                      defaultMessage="This field is required."
                    />
                  )
                }
                value={last}
                label={
                  <FormattedMessage
                    id="ContactForm.lastName"
                    defaultMessage="Last Name"
                  />
                }
                name="last"
                id="last"
                onChange={(e) => setLast(e.target.value)}
                slotProps={{ htmlInput: { maxLength: 255 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid>
              <TextField
                required
                type="email"
                error={submitStatus === SubmitState.Validate && !email}
                helperText={
                  submitStatus === SubmitState.Validate &&
                  !email && (
                    <FormattedMessage
                      id="ContactForm.thisFieldIsRequired"
                      defaultMessage="This field is required."
                    />
                  )
                }
                value={email}
                label={
                  <FormattedMessage
                    id="ContactForm.email"
                    defaultMessage="Email"
                  />
                }
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                slotProps={{ htmlInput: { maxLength: 255 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid>
              <FormControlLabel
                control={
                  <Switch
                    value={internalInd}
                    name="internalInd"
                    id="internalInd"
                    onChange={(e) => setInternalInd(e.target.checked)}
                  />
                }
                label={
                  <FormattedMessage
                    id="ContactForm.oracleHealthEmployee"
                    defaultMessage="Oracle Health Employee"
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid>
              <FormControl
                required
                error={
                  submitStatus === SubmitState.Validate &&
                  selectedCategories.length === 0
                }
              >
                <FormLabel>
                  <FormattedMessage
                    id="ContactForm.category"
                    defaultMessage="Category"
                  />
                </FormLabel>
                <FormGroup>
                  {categories.map((category) => (
                    <FormControlLabel
                      key={category}
                      control={
                        <Checkbox
                          checked={
                            selectedCategories.filter((c) => c === category)
                              .length === 1
                          }
                          onChange={handleChangeCategories}
                          name={category}
                        />
                      }
                      label={
                        <FormattedMessage
                          id={`ContactForm.${camelCase(category)}`}
                          defaultMessage={category}
                        />
                      }
                    />
                  ))}
                </FormGroup>
                {submitStatus === SubmitState.Validate &&
                  selectedCategories.length === 0 && (
                    <FormHelperText>
                      <FormattedMessage
                        id="ContactForm.thisFieldIsRequired"
                        defaultMessage="This field is required."
                      />
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>
          {selectedCategories.includes("Other") && (
            <Grid container spacing={2}>
              <Grid>
                <TextField
                  required={selectedCategories.includes("Other")}
                  error={
                    submitStatus === SubmitState.Validate &&
                    selectedCategories.includes("Other") &&
                    !otherText
                  }
                  helperText={
                    submitStatus === SubmitState.Validate &&
                    selectedCategories.includes("Other") &&
                    !otherText && (
                      <FormattedMessage
                        id="ContactForm.thisFieldIsRequired"
                        defaultMessage="This field is required."
                      />
                    )
                  }
                  value={otherText}
                  label={
                    <FormattedMessage
                      id="ContactForm.other"
                      defaultMessage="Please specify"
                    />
                  }
                  name="other"
                  id="other"
                  onChange={(e) => setOtherText(e.target.value)}
                  slotProps={{ htmlInput: { maxLength: 126 } }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid>
              <TextField
                required
                error={submitStatus === SubmitState.Validate && !body}
                helperText={
                  submitStatus === SubmitState.Validate &&
                  !body && (
                    <FormattedMessage
                      id="ContactForm.thisFieldIsRequired"
                      defaultMessage="This field is required."
                    />
                  )
                }
                value={body}
                label={
                  <FormattedMessage
                    id="ContactForm.howMayWeHelpYou"
                    defaultMessage="How may we help you?"
                  />
                }
                name="body"
                id="body"
                onChange={(e) => setBody(e.target.value)}
                multiline
                minRows={6}
                maxRows={20}
                sx={{ minWidth: "400px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Button
              type="submit"
              color="primary"
              disabled={loading === LoadState.Loading}
            >
              {loading === LoadState.Loading ? (
                <LoadingIndicator loading={loading} />
              ) : (
                <FormattedMessage
                  id="ContactForm.submit"
                  defaultMessage="Submit"
                />
              )}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          autoHideDuration={6000}
          open={submitStatus === SubmitState.Error}
          onClose={handleAlertClose}
        >
          <Alert severity="error">
            <FormattedMessage
              id="ContactForm.anErrorHasOcurredPleaseTryAgainLater"
              defaultMessage="An error has ocurred. Please try again later"
            />
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          open={submitStatus === SubmitState.Success}
          onClose={handleAlertClose}
        >
          <Alert severity="success">
            <FormattedMessage
              id="ContactForm.contactRequestSubmittedSuccessfully"
              defaultMessage="Contact request submitted successfully!"
            />
          </Alert>
        </Snackbar>
      </form>
    </Box>
  );
};

export default ContactForm;
